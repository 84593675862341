import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

import { useGlobalContext } from 'hooks';
import Login from 'pages/login';
import IdleSessionHandler from '../zg_utils/IdleSessionHandler';

const ProtectedPage = ({ children }) => {
  const { authenticated, loading } = useGlobalContext();
  const router = useRouter();

  useEffect(() => {
    if (authenticated || loading || !router.isReady) return;

    router.replace({
      pathname: '/login',
      shallow: true,
      query: router.query,
    });
  }, [authenticated, loading, router]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return <Login />;
  }

  return (
    <>
      <IdleSessionHandler
        expirationHandler={() => {
          router.push({ pathname: '/api/logout/', query: router.query });
        }}
      />
      {children}
    </>
  );
};

ProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(ProtectedPage);
