import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';
import Typography from '@wui/basics/typography';

import { useScreenSize } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    justify: 'left',
    height: '100%',
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    background: theme.palette.common.white,
  },
  menu: {
    padding: 20,
  },
}));

const NavMenu = ({ open, onClose, anchorEl, title }) => {
  const classes = useStyles();
  const { smDown } = useScreenSize();
  const router = useRouter();

  const onKeyDown = e => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const logout = () => {
    router.push({ pathname: '/api/logout/', query: router.query });
  };

  const content = (
    <MenuItem role="button" onClick={logout} tabIndex="0">
      <Typography variant="body1">
        <Link href="/api/logout/">
          <a tabIndex="-1">Sign out</a>
        </Link>
      </Typography>
    </MenuItem>
  );

  return smDown ? (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.root}
      onKeyDown={onKeyDown}
      id="nav-menu"
      aria-hidden={!open}
    >
      <Paper variant="outlined" className={classes.menu}>
        <Grid container direction="row">
          <Typography bold variant="h3" component="h2" color="primary">
            {title}
          </Typography>

          <Spacer h={24} />

          <Button onClick={onClose}>Close</Button>
        </Grid>

        <Spacer v={16} />
        {content}
      </Paper>
    </Modal>
  ) : (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      keepMounted
      open={open}
      onClose={onClose}
      PaperProps={{ variant: 'outlined' }}
      id="nav-menu"
      aria-hidden={!open}
    >
      {content}
    </Menu>
  );
};

NavMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  title: PropTypes.string,
};

NavMenu.defaultProps = {
  anchorEl: null,
  title: '',
};

export default observer(NavMenu);
